<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center space-x-2" :class="baseClass">
      <slot name="image"></slot>
      <div
        @click="onUserClick()"
        :class="[
          'user-click',
          userProfileOpened ? 'flex flex-col items-center text-center' : 'flex flex-col',
          hideCursor ? '' : 'cursor-pointer'
        ]"
      >
        <div
          :class="[userClass, userProfileOpened ? 'justify-center text-base leading-6' : 'leading-5']"
          class="flex items-center text-sm font-semibold"
        >
          <BaseTooltip v-if="title.length > maxTitleLength">
            <template #target>
              <p class="truncate">
                {{ truncate(title, maxTitleLength) }}
              </p>
            </template>
            <template #default>
              {{ title }}
            </template>
          </BaseTooltip>
          <span v-else>{{ title }}</span>
          <div
            v-if="showOnlineStatus && userData && !userData.deactivated_at"
            :class="onlineStatus ? 'bg-green-600' : 'border border-gray-400'"
            class="ml-1.5 h-2 w-2 rounded-full pl-1.5"
          ></div>
        </div>
        <BaseTooltip v-if="subText">
          <template #target>
            <div class="text-xs font-medium leading-none text-gray-400">{{ truncate(subText, maxTextLength) }}</div>
          </template>
          <template #default v-if="subText.length > maxTextLength">
            <p class="w-48">{{ subText }}</p>
          </template>
        </BaseTooltip>
        <div class="mt-4 flex flex-col items-center space-y-0.5 text-xs font-medium" v-else-if="streamCompanies">
          <div v-for="comp in userCompanies" class="flex items-center space-x-1" :key="comp">
            <ChatImage v-if="comp.image" size="xxs" :item="getCompany(comp)"></ChatImage>
            <span>{{ comp }}</span>
          </div>
          <div v-if="user.role_title" :class="subtextClass">
            {{ user.role_title }}
          </div>
        </div>
      </div>
    </div>
    <slot name="options"></slot>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useMapGetter } from '@/store/map-state';
import { truncate } from '@/composeables/filters';
import ChatImage from './ChatImage.vue';
import useEmitter from '@/composeables/emitter';

const $emitter = useEmitter();
const { client } = useMapGetter();

const props = defineProps({
  title: String,
  subText: String,
  showOnlineStatus: Boolean,
  user: Object,
  userProfileOpened: Boolean,
  hideProfile: Boolean,
  streamCompanies: Array,
  maxTextLength: { type: Number, default: 24 },
  customClass: { type: String, default: 'text-sm' },
  baseClass: String,
  hideCursor: { type: Boolean, default: false },
  subtextClass: { type: String, default: 'text-gray-400' },
  maxTitleLength: { type: Number, default: 25 }
});

// USER
const userPayload = ref(null);
const stateListener = ref(null);

const userData = computed(() => {
  return userPayload.value;
});

onMounted(() => {
  userPayload.value = props.user;
  stateListener.value = client.value.on('user.presence.changed', event => {
    if (userData.value.id === event.user.id) {
      userPayload.value.online = event.user.online;
    }
  });
});

onUnmounted(() => {
  if (stateListener.value) {
    stateListener.value.unsubscribe();
  }
});

const onlineStatus = computed(() => {
  return client.value.user.id === userData.value.id ? client.value.user.online : userData.value.online;
});
const userClass = computed(() => {
  let customClass = userData.value && userData.value.deactivated_at ? 'text-gray-400' : 'text-white';
  return props.hideCursor || (props.user && client.value.user.id === props.user.id)
    ? customClass
    : customClass + ' cursor-pointer';
});
const userCompanies = computed(() => {
  return userData.value.companies_data?.length ? userData.value.companies_data.map(c => c.name) : [];
});

function onUserClick() {
  if (!props.hideProfile && props.user && client.value.user.id != userData.value.id) {
    $emitter.$emit('show:user-bio', userData.value);
  }
}

// COMPANY
function getCompany(name) {
  if (props.streamCompanies) {
    return props.streamCompanies.find(x => x.name == name);
  }
  return null;
}
</script>
